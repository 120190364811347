#counters{height:100px;line-height:normal;text-align:center;margin-bottom:70px}#counters .counter-wrapper{display:inline-block}#counters .counter-wrapper:first-of-type{margin-right:50px}#counters .counter-wrapper h4{color:#616060}#counters .odometer-digit{box-shadow:0 3px 4px 0 rgba(0,0,0,.2),inset 2px 4px 0 0 rgba(255,255,255,.08);border:1px solid rgba(0,0,0,.1);background:#f5f5f5 none;margin-left:4px;margin-right:4px}#counters .counter{font-size:50px;font-weight:700;margin-bottom:20px}#counters .odometer-formatting-mark{position:relative;top:10px}
#counters .counter-wrapper {
    display: inline-block;
    padding-left: 35px;
}
#counters {
    height: 100px;
    line-height: normal;
    text-align: center;
    margin-bottom: 70px;
    margin-top: 40px;
}